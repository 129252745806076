import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useCollectivesList() {
  // Use toast
  const toast = useToast()

  const refCollectivesListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { label: '#', key: 'id', sortable: true },
    { label: 'Naam', key: 'name', sortable: false },
    { label: 'Organisatie', key: 'companyID', sortable: true },
    { label: 'Acties', key: 'actions', sortable: false },
  ]
  const perPage = ref(10)
  const totalLocations = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(false)
  const showOverlay = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refCollectivesListTable.value ? refCollectivesListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalLocations.value,
    }
  })

  const refetchCollectivesData = () => {
    refCollectivesListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], () => {
    refetchCollectivesData()
  })

  const fetchCollectives = (ctx, callback) => {
    store
      .dispatch('app-collectives-list/fetchCollectives', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortDesc: isSortDirDesc.value,
        sortBy: sortBy.value,
      })
      .then(response => {
        const { items, _meta } = response.data

        callback(items)
        showOverlay.value = false
        totalLocations.value = _meta.totalCount
      })
      .catch(() => {
        showOverlay.value = false
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error bij het ophalen van de collectieven',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  return {
    fetchCollectives,
    tableColumns,
    perPage,
    currentPage,
    totalLocations,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refCollectivesListTable,
    refetchCollectivesData,
    showOverlay,
  }
}
